<template>
    <div class="container rounded bg-white mt-5 mb-5">
        <div class="row">
            <div class="col-md-3 border-right">
                <div class="d-flex flex-column align-items-center text-center p-3">
                    <div class="profilepic">
                        <img class="profilepic__image" :src="formFoto.avatar" width="125" height="125" alt="Profibild" />
                        <div class="profilepic__content">
                            <input class="inputfile" type="file" name="pic" accept="image/*" @change="simpanAvatar">
                            <label>
                                <i class="fas fa-camera"></i>
                            </label>
                            <span class="profilepic__text">Edit Profile</span>
                        </div>
                    </div>
                </div>


                <div class="d-flex flex-column align-items-center text-center p-3 "><span class="font-weight-bold">{{
                    formProfil.nama }}</span><span class="text-black-50">{{ formProfil.email
    }}</span><span>
                    </span></div>

                <div class="text-center">
                    <a href="/project"><span><i class="mdi mdi-open-in-new"></i> Lihat Project</span></a>
                </div>
                <div class="mt-5 text-center"><button class="btn btn-danger profile-button" type="button" @click="logout"><i
                            class="mdi mdi-logout"></i>Logout</button></div>

                <div class="mt-5 text-center">
                    <a href="/project/dashboard"><span><i class="mdi mdi-arrow-left-circle-outline"></i> Kembali</span></a>
                </div>

            </div>
            <div class="col-md-5 border-right">
                <div class="p-3 py-5">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h4 class="text-right">Profile Settings</h4>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12"><label class="labels">Nama</label>
                            <input type="text" class="form-control" placeholder="Masukkan nama Anda"
                                v-model="formProfil.nama">
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12"><label class="labels">Email</label><input type="text" class="form-control"
                                placeholder="Masukkan email Anda" v-model="formProfil.email"></div>

                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12"><label class="labels">Nomor Telepon</label><input type="text"
                                class="form-control" placeholder="Masukkan telepon Anda" v-model="formProfil.notelp"></div>

                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12"><label class="labels">Alamat</label>
                            <textarea v-model="formProfil.alamat" class="form-control"></textarea>
                        </div>

                    </div>
                    <div class="mt-5 text-center"><button class="btn btn-primary profile-button" @click="simpanProfil"
                            type="button">Simpan
                            Profil</button></div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="p-3 py-5">
                    <div class="d-flex justify-content-between align-items-center experience"><span>Edit
                            Password</span></div><br>
                    <div class="col-md-12"><label class="labels">Password Lama</label><input type="text"
                            class="form-control" placeholder="Masukkan password lama"
                            v-model="formEditPassword.passwordLama"></div> <br>
                    <div class="col-md-12"><label class="labels">Password Baru</label><input type="text"
                            class="form-control" placeholder="Masukkan password baru"
                            v-model="formEditPassword.passwordBaru"></div>
                </div>
                <div class="mt-3 text-center"><button class="btn btn-primary profile-button" type="button"
                        @click="EditPassword">Edit
                        Password</button></div>
            </div>
        </div>
    </div>
</template>

<script>
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import bcrypt from 'bcryptjs';

import axios from "axios";

import appConfig from "@/app.config";
import Swal from "sweetalert2";

export default {
    page: {
        title: "Data Ability",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: {},
    data() {
        return {
            title: "Profile",
            items: [
                {
                    text: "Profile",
                },
            ],
            baseapi: localStorage.getItem("baseapi"),
            idProyek: localStorage.getItem("idProyek"),
            user: JSON.parse(localStorage.getItem('user')),
            formProfil: {
                nama: null,
                email: null,
                notelp: null,
                alamat: null
            },
            formEditPassword: {
                passwordLama: null,
                passwordBaru: null,
                passwordLamaan: null
            },
            formFoto: {
                avatar: null
            }
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        loadData() {
            axios.get(this.baseapi + "api/user/" + this.user.id,
                {
                    headers: {
                        Authorization: `Bearer ` + this.user.accessToken
                    }
                }).then(response => {
                    var dataResponse = response.data.data
                    this.formProfil.nama = dataResponse.nama
                    this.formProfil.email = dataResponse.email
                    this.formProfil.notelp = dataResponse.no_hp
                    this.formProfil.alamat = dataResponse.alamat
                    this.formFoto.avatar = dataResponse.avatar
                    this.formEditPassword.passwordLamaan = dataResponse.password

                    // localStorage.setItem("user", JSON.stringify(response.data.data));
                    console.log(response)
                })
        },
        updateUser() {
            axios.get(this.baseapi + "api/user/" + this.user.id,
                {
                    headers: {
                        Authorization: `Bearer ` + this.user.accessToken
                    }
                }).then(response => {
                    var dataResponse = response.data.data
                    this.formProfil.nama = dataResponse.nama
                    this.formProfil.email = dataResponse.email
                    this.formProfil.notelp = dataResponse.no_hp
                    this.formProfil.alamat = dataResponse.alamat
                    this.formFoto.avatar = dataResponse.avatar
                    this.formEditPassword.passwordLamaan = dataResponse.password

                    var formData = {
                        accessToken : this.user.accessToken,
                        avatar : dataResponse.avatar,
                        berlangganan : dataResponse.berlangganan,
                        email : dataResponse.email,
                        id : dataResponse.id,
                        nama : dataResponse.nama,
                        role : dataResponse.role
                    }

                    localStorage.setItem("user", JSON.stringify(formData));
                    console.log(response)
                })
        },
        simpanProfil() {
            var bodyFormData = new FormData();
            bodyFormData.append('nama', this.formProfil.nama);
            bodyFormData.append('email', this.formProfil.email);
            bodyFormData.append('no_hp', this.formProfil.notelp);
            bodyFormData.append('alamat', this.formProfil.alamat);
            axios.post(this.baseapi + "api/user/" + this.user.id, bodyFormData,
                {
                    headers: {
                        Authorization: `Bearer ` + this.user.accessToken
                    }
                }).then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.loadData()
                }).catch(err => {
                    Swal.fire({
                        icon: 'error',
                        title: err.response.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    return err;
                })
        },
        simpanAvatar(event) {
            console.log(event.target.files);
            var bodyFormData = new FormData();
            bodyFormData.append('avatar', event.target.files[0]);
            axios.post(this.baseapi + "api/user/" + this.user.id, bodyFormData,
                {
                    headers: {
                        Authorization: `Bearer ` + this.user.accessToken
                    }
                }).then(response => {
                    
                    Swal.fire({
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.updateUser()
                }).catch(err => {
                    Swal.fire({
                        icon: 'error',
                        title: err.data.message,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    return err;
                })
        },
        async EditPassword() {

            const match = await bcrypt.compare(this.formEditPassword.passwordLama, this.formEditPassword.passwordLamaan);



            if (match) {
                var bodyFormData = new FormData();
                bodyFormData.append('password', this.formEditPassword.passwordBaru);
                axios.post(this.baseapi + 'api/user/' + this.user.id, bodyFormData,
                    {
                        headers: {
                            Authorization: `Bearer ` + this.user.accessToken
                        }
                    }).then(response => {

                        Swal.fire({
                            icon: 'success',
                            title: response.data.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.loadData()
                    }).catch(err => {
                        Swal.fire({
                            icon: 'error',
                            title: err.response.data.message,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        return err;
                    })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: "Password lama tidak sesuai",
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        },
        logout() {
            Swal.fire({
                title: 'Keluar',
                text: "Apakah Anda yakin ingin keluar?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Tidak',
                confirmButtonText: 'Ya'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$router.push('/logout')
                }
            })
        }
    }
}
</script>

<style>
.profilepic {
    position: relative;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #111;

}

.profilepic:hover .profilepic__content {
    opacity: 1;
}

.profilepic:hover .profilepic__image {
    opacity: .5;
}

.profilepic__image {
    object-fit: cover;
    opacity: 1;
    transition: opacity .2s ease-in-out;
}

.profilepic__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    opacity: 0;
    transition: opacity .2s ease-in-out;
}

.profilepic__icon {
    color: white;
    padding-bottom: 8px;
}

.fas {
    font-size: 20px;
}

.profilepic__text {
    text-transform: uppercase;
    font-size: 12px;
    width: 50%;
    text-align: center;
}

.inputfile {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 50px;
}

.inputfile+label {
    font-size: 1.25rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    width: 50px;
    height: 50px;
    pointer-events: none;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
}

.inputfile+label svg {
    fill: #fff;
}
</style>